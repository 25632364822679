import React, { useState, createContext, useContext } from 'react';
import { Snackbar as MaterialSnackbar } from '@material-ui/core';

const SnackbarContext = createContext(null);
const useSnackbar = () => useContext(SnackbarContext);
export default useSnackbar;

export const SnackbarProvider = ({ children, ...props }) => {
  const [notification, setNotification] = useState(null);

  // Renders MaterialSnackbar as a sibling to Snackbar's children but
  // provides setNotification to them through context
  return (
    <>
      <MaterialSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!notification}
        autoHideDuration={4000}
        onClose={() => setNotification(null)}
        message={<span>{notification}</span>}
        // Allowing for additional props / overriding above
        {...props}
      />
      <SnackbarContext.Provider value={setNotification}>
        {children}
      </SnackbarContext.Provider>
    </>
  );
};
