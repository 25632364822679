import React from 'react';
import ThemeProvider from './src/theme';
import { SnackbarProvider } from './src/providers/SnackbarProvider';
import { UserProvider } from './src/providers/UserProvider';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <SnackbarProvider>
      <UserProvider>{element}</UserProvider>
    </SnackbarProvider>
  </ThemeProvider>
);
