import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react';
import firebase from 'firebase/app';

const UserContext = createContext(null);
const useUser = () => useContext(UserContext);
export default useUser;

// Passes user data through Context including the
// Firebase user object, active role and Firestore user doc
// undefined values indictate they are still loading
// while null is intentionally value-less
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [role, setRole] = useState();
  const [doc, setDoc] = useState();

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        user.getIdTokenResult().then(({ claims }) => {
          setRole(claims.role || null);
        });
      } else setRole(null);
    });
  }, []);

  useEffect(() => {
    if (user)
      return firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .onSnapshot((snap) => {
          setDoc(snap.data());
        });
    else setDoc(null);
  }, [user]);

  const reloadUser = useCallback(async () => {
    await firebase.auth().currentUser.getIdToken(true);
    await firebase.auth().currentUser.reload();
    setUser(firebase.auth().currentUser);
  }, []);

  // Allows for manual count incrementation locally
  // rather than waiting for the automated Cloud Function
  const incrementQuestionsAnswered = useCallback(() => {
    setDoc((doc) => ({ ...doc, questionsAnswered: doc.questionsAnswered + 1 }));
  }, []);

  return (
    <UserContext.Provider
      value={{ user, role, doc, reloadUser, incrementQuestionsAnswered }}
    >
      {children}
    </UserContext.Provider>
  );
};
