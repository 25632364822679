module.exports = [{
      plugin: require('../plugins/firebase/gatsby-browser.js'),
      options: {"plugins":[],"credentials":{"apiKey":"AIzaSyDX9GkqtKcVuS_Kvjo9iPcfax-tqyV3kkU","authDomain":"college-unfiltered-v2.firebaseapp.com","databaseURL":"https://college-unfiltered-v2.firebaseio.com","projectId":"college-unfiltered-v2","storageBucket":"college-unfiltered-v2.appspot.com","messagingSenderId":"1030415973417","appId":"1:1030415973417:web:c642e36535ec5500f9b038","measurementId":"G-EH47B3GFY0"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"College Unfiltered","short_name":"College Unfiltered","start_url":"/","background_color":"#002F6C","theme_color":"#002F6C","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"87cdba693de3732f73f4c74136d81d34"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
