import React from 'react';
import { Grommet } from 'grommet';
import theme from './theme.json';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';
import normalize from 'emotion-normalize';
import 'typeface-lato';

export default function ThemeProvider({ children }) {
  return (
    <Grommet theme={theme}>
      <EmotionThemeProvider theme={theme}>
        <Global styles={normalize} />
        <Global
          styles={`
            ::-webkit-scrollbar {
              -webkit-appearance: none;
              width: 7px;
            }
            ::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: rgba(0,0,0,.5);
              box-shadow: 0 0 1px rgba(255,255,255,.5);
            }
          `}
        />
        {children}
      </EmotionThemeProvider>
    </Grommet>
  );
}
